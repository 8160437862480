@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  50%{
    -webkit-transform: translateY(4%);
            transform: translateY(4%);
  }
  65%{
    -webkit-transform: translateY(-2%);
            transform: translateY(-2%);
  }
  80%{
    -webkit-transform: translateY(2%);
            transform: translateY(2%);
  }
  95%{
    -webkit-transform: translateY(-1%);
            transform: translateY(-1%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}


@keyframes fadeIn {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  50%{
    -webkit-transform: translateY(4%);
            transform: translateY(4%);
  }
  65%{
    -webkit-transform: translateY(-2%);
            transform: translateY(-2%);
  }
  80%{
    -webkit-transform: translateY(2%);
            transform: translateY(2%);
  }
  95%{
    -webkit-transform: translateY(-1%);
            transform: translateY(-1%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

/*@import url('/stylesheets/okta-theme.css'); !*shows as error in IDE but resolves to public folder *!*/

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

.material-icons.md-light { color: rgba(255, 255, 255, 1); }

.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.MuiIconButton-colorInherit { color: rgba(0, 0, 0, 0.54);}

.MuiIcon-root { color: rgba(0, 0, 0, 0.54);}

.MuiInputBase-input {
  padding-top: 20px;
}
.rpv-search__highlight{background-color:#ff0;background-color:var(--rpv-search__highlight-background-color);border-radius:.25rem;position:absolute}.rpv-search__highlight--current{background-color:#ff0;background-color:var(--rpv-search__highlight--current-background-color)}.rpv-search__popover{padding:0 .5rem}.rpv-search__popover-input-counter{align-items:center;display:flex;margin-bottom:.5rem;position:relative;width:12rem}.rpv-search__popover-counter{align-items:center;bottom:0;display:flex;padding-right:.25rem;position:absolute;right:0;top:0}.rpv-search__popover-label{align-items:center;display:flex;margin-bottom:.5rem}.rpv-search__popover-label-checkbox{margin-right:.25rem}.rpv-search__popover-footer{align-items:center;display:flex}.rpv-search__popover-footer-item{padding:0 .25rem}.rpv-search__popover-footer-button{margin-left:auto}:root{--rpv-search__highlight-background-color:#ff0;--rpv-search__highlight--current-background-color:#ff0}
